export const AppRoute = {
    Contact: (): string => '/contact-us',
    Eula: (): string => '/terms',
    AudioOneTerms: (): string => '/legal/audio-one-terms',
    NotFound: (): string => '*',
    Home: (): string => '/',
    Privacy: (): string => '/privacy',
    GoogleApiDisclosure: (): string => '/google-api-disclosure',
    ConsentForPersonalDataProcessing: (): string => '/consent-for-personal-data-processing',
};
