import { AppRoute } from 'constants/AppRoute';
import { HeaderNavItems } from 'constants/nav-items';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { ContentContainer } from 'containers/ContentContainer';
import { HeaderContainer } from 'containers/HeaderContainer';
import { MainContainer } from 'containers/MainContainer';
import { ReactComponent as Logo } from 'icons/logo.svg';

import { HeaderNav } from 'components/HeaderNav';

const CnContent = cn('content');

export const AudioOneTerms: React.FC = () => {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderContainer customClassName="header-container_black">
                <header className="header container">
                    <Link to={AppRoute.Home()}>
                        <Logo className="header-logo" />
                    </Link>
                    <HeaderNav items={HeaderNavItems} />
                </header>
            </HeaderContainer>
            <MainContainer>
                <ContentContainer>
                    <div className="container">
                        <div className={CnContent()}>
                            {/* Введение */}
                            <div className={CnContent('textBlock')}>
                                <h1 className={CnContent('title')}>End User License Agreement</h1>
                                <div className={CnContent('text')}>Version No. 1 dated 10.12.2024</div>
                            </div>
                            <div className={CnContent('textBlock')}>
                                <div className={CnContent('text')}>
                                    The subject of this End User License Agreement (hereinafter– License Agreement) is the granting of a non-exclusive right to use Audio One to you (the user) and/or Content Files, the copyright holder of which is Bitnite LLP, as well as the rights and obligations of the Copyright Holder and the User.
                                </div>
                                <div className={CnContent('text')}>
                                    The Copyright Holder requests that users carefully read this license agreement before downloading and installing the Application on their device. By downloading, installing, and/or using Audio One, the User agrees to comply with the terms of the License Agreement, to be guided by its provisions when using Audio One.
                                </div>
                                <div className={CnContent('text')}>
                                    Before using the Application, the User must read the Privacy Policy, which is located at <a href="https://bitnite.studio/privacy">https://bitnite.studio/privacy</a>. Refunds are governed by the Apple Inc. refund policy, located at <a href="https://www.apple.com/legal/internet-services/itunes/">https://www.apple.com/legal/internet-services/itunes/</a>.
                                </div>
                                <div className={CnContent('text')}>
                                    This license agreement is an integral part of the License Agreement of Bitnite LLP, located at <a href="https://bitnite.studio/terms">https://bitnite.studio/terms</a>. In the event of discrepancies between this license agreement and the license agreement located at <a href="https://bitnite.studio/terms">https://bitnite.studio/terms</a>, the Parties will apply the terms of this License Agreement.
                                </div>
                                <div className={CnContent('text')}>
                                    If the User does not agree with the provisions of the License Agreement, Privacy Policy, Refund Policy, the User must stop using Audio One, remove it from the Device.
                                </div>
                                <div className={CnContent('text')}>
                                    This License Agreement is posted on the website at: <a href="https://bitnite.studio/legal/audio-one-terms/">https://bitnite.studio/legal/audio-one-terms/</a>.
                                </div>
                            </div>
                            {/* Article 1 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 1. Terms and Definitions</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>
                                        <strong>1. Audio One / Application</strong> – software (Application) that allows the User to create and edit audio files.
                                        The Application&apos;s functionality is described within the Application itself.
                                        The Application includes:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- sound recording and processing;</li>
                                            <li>- recording and mixing audio files (voice and beat or instruments);</li>
                                            <li>- conversion from one format to another;</li>
                                            <li>- editing tags and covers of audio files;</li>
                                            <li>- support for various sound effects;</li>
                                            <li>- adjusting the length of audio files;</li>
                                            <li>- providing access to a three- and ten-band equalizer;</li>
                                            <li>- creation of ringtones;</li>
                                            <li>- use of Content Files to create Content.</li>
                                        </ul>
                                        The Application includes all updates and modifications provided by the Copyright Holder to the User.
                                    </li>
                                    <li>
                                        <strong>2. Subscription</strong> – the scope of rights granted to the User for a fee depending on the functionality available to the User or the method of using the Content Files.
                                    </li>
                                    <li>
                                        <strong>3. Basic Version of the Application</strong> – a version of the Application with a limited list of functional capabilities. The list of available functionality is detailed in the Application.
                                    </li>
                                    <li>
                                        <strong>4. Device</strong> – iPhone, iPad or Mac legally owned by the User. Models of the described devices must support the operation of the Application.
                                    </li>
                                    <li>
                                        <strong>5. User</strong> – a person using the Application.
                                    </li>
                                    <li>
                                        <strong>6. Content</strong> – audio files that are created by the User when using the Application, both with and without the use of Content Files.
                                    </li>
                                    <li>
                                        <strong>7. Content Files</strong> – files (sounds, effects, audio files) belonging to the Copyright Holder, which are placed in the Application by the Copyright Holder for the User to create Content.
                                    </li>
                                    <li>
                                        <strong>8. Materials</strong> – materials uploaded by the User to create Content.
                                    </li>
                                </ol>
                            </div>
                            {/* Article 2 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 2. General Conditions of the Application of the License Agreement</h2>
                                <i>Amendments to the License Agreement</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The Copyright Holder has the right to make changes to the License Agreement at its own discretion at any time, including in the event of changes to the functionality of the Application.</li>
                                    <li>2. The new version of the License Agreement shall apply from the moment the new version of the License Agreement is published on the website at <a href="https://bitnite.studio/legal/audio-one-terms/">https://bitnite.studio/legal/audio-one-terms/</a>.</li>
                                    <li>3. The version posted on the website at <a href="https://bitnite.studio/legal/audio-one-terms/">https://bitnite.studio/legal/audio-one-terms/</a> is current.</li>
                                    <li>4. The Copyright Holder may, at its discretion, notify the User of any changes to the License Agreement.</li>
                                    <li>5. The Copyright Holder strongly advises the User to re-read the License Agreement before each launch of the Application.</li>
                                    <li>6. If the User uses the Application after changes have been made to the License Agreement, this means that the User agrees to the new version of the License Agreement.</li>
                                    <li>7. By continuing to use the Application after the changes, the User agrees to the new version of the License Agreement.</li>
                                </ol>
                                <i>Commencement of the License Agreement</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>8. The license agreement regarding the use of the basic version of the Application is valid from the moment the User starts using the Application.</li>
                                    <li>9. The License Agreement for a Subscription is effective from the moment of payment for the Subscription.</li>
                                </ol>
                            </div>
                            {/* Article 3 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 3. Protection of the Rights of the Copyright Holder. Permitted Use and Restrictions in the Use of the Application, Content Files</h2>
                                <i>Protection of the Rights of the Copyright Holder</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The Application and Content Files are protected by copyright and intellectual property laws and international intellectual property treaties.</li>
                                    <li>2. The Application, Content Files are protected by copyright, trademarks and other laws of the Republic of Kazakhstan and foreign countries.</li>
                                </ol>
                                <i>Permitted Use and General Restrictions on Use of the Application</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>
                                        3. The Copyright Holder grants the User a non-exclusive, limited, revocable, non-transferable license to use the Application, subject to the following features (permitted use):
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- The User may use the Application on no more than ten Devices, and the User guarantees that he/she will be the only user of the Application;</li>
                                            <li>- the functionality of the Application depends on the User purchasing a Subscription in relation to the Functionality of the Application or the User using the Basic version of the Application;</li>
                                            <li>- other terms of use are set out below in the License Agreement.</li>
                                        </ul>
                                    </li>
                                    <li>4. The license granted under the terms of the License Agreement is for use by one person only and may not be shared.</li>
                                    <li>
                                        5. The user does not have the right (restrictions):
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- sell, distribute, sublicense, assign the Application without the written consent of the Copyright Holder;</li>
                                            <li>- copy the Application;</li>
                                            <li>- use the Application in ways that are not provided for in the License Agreement;</li>
                                            <li>- use the Application in criminal or illegal activities, or for purposes that are contrary to current regulations or violate the rights of third parties (including intellectual property rights);</li>
                                            <li>- decompile, disassemble, reverse engineer or create intellectual property based on the Application or its parts (except for cases specified in the License Agreement);</li>
                                            <li>- attempt to circumvent technical limitations set in the Application;</li>
                                            <li>- use third-party programs or other means to improve and automate the functionality of the Application;</li>
                                            <li>- restrict the use of the Application in any way;</li>
                                            <li>- provide the opportunity to use the Application to third parties.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        6. The Copyright Holder has the right to terminate the License Agreement if it is established that the User has violated one of the restrictions in accordance with Article 11 of the License Agreement.
                                    </li>
                                </ol>
                                <i>Permitted Use and General Restrictions on Use of Content Files</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The Content Files are fully owned by the Copyright Holder, and their use is carried out in accordance with the terms of this License Agreement.</li>
                                    <li>2. The User is granted a non-exclusive, non-sublicensable and non-transferable license to use the Content Files to create Content, the term of which depends on the term of the Subscription.</li>
                                    <li>3. The User has the right to use Content Files only for the creation of Content.</li>
                                    <li>
                                        4. The User has no right to:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- distribute Content Files separately;</li>
                                            <li>- sell, sublicense Content Files, Content (except in cases specified in the License Agreement);</li>
                                            <li>- <strong>create a database or data set using Content Files that include or consist of Content Files;</strong></li>
                                            <li>- create Content that violates the Intellectual Property Rights of any person: copyright, moral rights, rights in relation to a trademark, corporate identity, patent, trade secret, unfair competition, right to privacy, right to publicity and any other property rights;</li>
                                            <li>- create Content that is unlawful, harmful, threatening, obscene, violent, abusive, tortious, defamatory, libelous, vulgar, indecent, invasive of another&apos;s privacy, hateful;</li>
                                            <li>- upload, transmit, store or make available files or code that contain viruses, malicious code, malware or any components designed to damage the Application or limit its functionality.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        5. The User has the right to use the Content Files to the extent provided by the Subscription:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- the list of Content Files provided by the Basic version of the Application is set out in the Application;</li>
                                            <li>- the list of Content Files provided for by the Subscription in relation to Content Files is set out in the Appendix and will be marked with a special sign indicating that they are included in the Subscription.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        6. If the User uploads any materials to create Content, the rights to downloaded materials belong entirely to the User. The Copyright Holder does not claim any rights to the materials uploaded by the User to the Application. Also, the exclusive rights to the materials that are part of the Content remain with the User, the Copyright Holder does not claim them.
                                        The parties have established that the exclusive right to the created Content belongs to the User.
                                    </li>
                                    <li>
                                        7. The Copyright Holder does not control the materials uploaded by Users. However, if the Copyright Holder establishes circumstances indicating that the said materials violate the rights of third parties, provisions of applicable law, the Copyright Holder has the right to terminate the User&apos;s access to the Application, as well as use other methods to protect his interests.
                                    </li>
                                </ol>
                            </div>
                            {/* Article 4 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 4. Subscription Contents</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The User may use the Application and Content Files in accordance with the terms of the Subscriptions.</li>
                                    <li>2. The subscription is paid.</li>
                                    <li>3. The subscription is established in relation to the functionality of the Application or in relation to the Content Files.</li>
                                    <li>4. The User has the right to use Subscriptions both separately and together.</li>
                                    <li>
                                        <strong>5. Subscription regarding the functionality of the Application:</strong>
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- a non-exclusive, non-sublicensable, non-transferable license to the Application,</li>
                                            <li>- validity period: depends on the period of validity of the Subscription paid by the User;</li>
                                            <li>- Territory of operation: countries all over the world;</li>
                                            <li>- the functionality of the Subscription, provided for in paragraph 5 of Article 4 of the License Agreement, is listed in the Appendix;</li>
                                            <li>- when using the Application and its content, the User must comply with the restrictions established by the License Agreement;</li>
                                            <li>
                                                - if the User has not purchased a subscription to the Content Files:
                                                <ul className={CnContent('unorderedList')}>
                                                    <li>- The User has the right to use Content Files only for the creation of Content for personal purposes and for non-commercial use.
                                                        Non-commercial use means the use of the Application, its functionality, created Content, Content Files, in activities that are not aimed at making a profit for the User (for example, for educational purposes, in charitable projects).
                                                    </li>
                                                    <li>- The User has no right to use Content created during the period of use of the Basic Version, including in the following cases:
                                                        <ul className={CnContent('unorderedList')}>
                                                            <li>- for publishing Content as an integral part of a work on social networks (including for monetization purposes);</li>
                                                            <li>- to create works that are used as advertising;</li>
                                                            <li>- to create works that are used for other commercial purposes.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                - if the User has also purchased a Paid Subscription, he/she has the right to use the Content Files in accordance with the conditions set out in paragraph 6 of Article 4 of the License Agreement.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>6. Paid Subscription for Content Files:</strong>
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- a non-exclusive, non-sublicensable, non-transferable license to the Content Files;</li>
                                            <li>- validity period: the validity period of the paid Subscription specified in the Appendix;</li>
                                            <li>- Territory of operation: countries all over the world;</li>
                                            <li>- The User has the right to use the Content Files and created Content for personal, non-commercial and commercial use;</li>
                                            <li>- Use for commercial purposes is permitted only if the Content was created during the period of validity of a paid Subscription.
                                                In the event of expiration of the Paid Subscription, the User has the right to use the available Content Files only to create Content for personal purposes and for non-commercial use;
                                            </li>
                                            <li>- when using the Application and its content, the User must comply with the restrictions established by the License Agreement;</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            {/* Article 5 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 5. Procedure for Using the Application</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. To start using the Application, you must install it on your Device via the AppStore.</li>
                                    <li>
                                        2. User guarantees:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- the characteristics of the User&apos;s Device correspond to the characteristics specified on the Website;</li>
                                            <li>- the User is an adult in accordance with the legislation of his place of residence, or if the User is a minor, he has received all necessary consents from authorized representatives;</li>
                                            <li>- by entering bank card details to pay for a subscription, the User confirms that he/she is the cardholder or an authorized representative of the cardholder;</li>
                                            <li>- that he has rights to the files being downloaded.</li>
                                        </ul>
                                    </li>
                                    <li>3. The User must use the Application within the limits of permitted use, taking into account the restrictions set forth in the License Agreement.</li>
                                </ol>
                            </div>
                            {/* Article 6 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 6. Subscription Registration</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. Subscription is made in the Application by selecting the appropriate type of subscription depending on the validity period, if provided in the Application.</li>
                                </ol>
                                <i>Subscription</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>2. The User pays for the Subscription for the functionality of the Application and/or for the Content Files, taking into account the selected period of validity, by entering the relevant data in the Application.</li>
                                    <li>3. The cost of the selected type of Subscription is indicated in the Appendix.</li>
                                    <li>4. Payment for a paid subscription is made using the functionality provided by AppStore.</li>
                                    <li>
                                        5. The Subscription renewal fee is debited automatically on the last day of the Subscription period. If the card details are invalid or there are insufficient funds in the account to pay for the subscription, the Copyright Holder will terminate access to the Application.
                                    </li>
                                    <li>
                                        6. Before renewing the Paid Subscription, the User has the right to cancel the Subscription for the next period no later than the penultimate day of the Subscription period. In this case, the Subscription will terminate on the last day of the Subscription period.
                                    </li>
                                    <li>
                                        7. If the User wishes to resume access to the Application after terminating access, he/she can do so in the Application.
                                    </li>
                                    <li>
                                        8. The Copyright Holder has the right to change the cost of the Subscription. The cost of the already paid period of the Subscription cannot be changed.
                                        The changed Subscription price does not apply to Subscriptions already in effect and paid for by the User.
                                    </li>
                                </ol>
                                <i>Trial Period, Special Offers</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>9. The Copyright Holder has the right to establish trial periods for a paid Subscription, as well as special offers for purchasing paid Subscription.</li>
                                    <li>10. The terms and conditions of trial periods, as well as special offers, will be displayed in the Application in separate sections of the Application.</li>
                                    <li>11. The Copyright Holder has the right to revoke or change the Trial Period at any time without prior notice and without any liability to the extent permitted by applicable law.</li>
                                    <li>
                                        12. The User agrees that the Copyright Holder may automatically begin charging the User for a paid Subscription on the first day after the end of the Trial Period at a recurring interval, which will be provided to the User in advance.
                                        IF THE USER WISHES TO OPT OUT OF SUCH A FEE, HE MUST CANCEL THE APPLICABLE PAID SUBSCRIPTION NO LATER THAN THE LAST DAY BEFORE THE RENEWAL DATE (CLAUSE 9 OF ARTICLE 6 OF THE LICENSE AGREEMENT).
                                    </li>
                                    <li>
                                        13. If the User cancels the access renewal, he/she loses access to the paid Subscription immediately after the expiration of the trial period, in which case the User can continue to use the Application in accordance with the terms of the Basic version.
                                    </li>
                                </ol>
                            </div>
                            {/* Article 7 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 7. Updates to the Application, Content Files</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The Copyright Holder updates the Applications, adds, deletes, changes or replaces Content Files at its own discretion.</li>
                                    <li>2. The Copyright Holder has the right to add or remove any functions of the Application at its own discretion with or without prior notice.</li>
                                    <li>3. The Copyright Holder has the right to send the User a notification about the need to update the Application due to the availability of a new version of the Application or automatic update of the Application.</li>
                                    <li>4. The Copyright Holder has the right not to support previous versions of the Application when updates or subsequent versions of the Application appear.</li>
                                    <li>5. The Copyright Holder has the right to make changes to the License Agreement in the event of an update to the Application (Article 2 of the License Agreement).</li>
                                </ol>
                            </div>
                            {/* Article 8 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 8. Suspension of Use of the Application</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>
                                        1. The Copyright Holder has the right to suspend/terminate the operation of the Application without prior notice to the User, compensation for damages, and without liability:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- if this is necessary for troubleshooting, maintenance or other similar activities;</li>
                                            <li>- if the User has violated the restrictions, as well as other conditions of the License Agreement.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            {/* Article 9 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 9. Personal Data</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The User confirms that he/she has read, understood and agreed with the Privacy Policy.</li>
                                    <li>2. The Copyright Holder collects and uses technical and related information to improve the operation of the Application. The Copyright Holder cannot identify the User using this information.</li>
                                    <li>
                                        3. The Copyright Holder uses the following services to collect technical and related information:
                                        <table className="personal-data-table">
                                            <thead>
                                                <tr>
                                                    <th>Service Name</th>
                                                    <th>Type of Information Processed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Amplitude</td>
                                                    <td>User actions in the Application and the operation of the Application</td>
                                                </tr>
                                                <tr>
                                                    <td>RevenueCat</td>
                                                    <td>Information about the User&apos;s payments for subscriptions, subscription renewals, and number of payments.</td>
                                                </tr>
                                                <tr>
                                                    <td>Firebase</td>
                                                    <td>User actions in the Application and the operation of the Application</td>
                                                </tr>
                                                <tr>
                                                    <td>BugSnag</td>
                                                    <td>Application Errors</td>
                                                </tr>
                                                <tr>
                                                    <td>Doorbell</td>
                                                    <td>Feedback from the User regarding the operation of the Application</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                </ol>
                            </div>
                            {/* Article 10 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 10. Responsibility</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The Application is provided &quot;as is&quot;.</li>
                                    <li>2. The Copyright Holder does not guarantee that the Application meets the User&apos;s expectations, is provided continuously, quickly, reliably and without errors, and will be available at any time.</li>
                                    <li>
                                        3. The Copyright Holder is not responsible for:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- specialized resources and the quality of public communication network channels through which the Application is accessed, as well as those caused by interruptions in the provision of services by counterparties and third parties;</li>
                                            <li>- breakdowns or other malfunctions of the Device that occurred during the use of the Application;</li>
                                            <li>- errors and/or violations related to the use of the Application and arising due to illegal actions of third parties;</li>
                                            <li>- failures and interruptions in the operation of the Application caused by force majeure;</li>
                                            <li>- failures and interruptions in the operation of the Application caused by actions of government authorities, including law enforcement agencies, to seize and/or confiscate or otherwise impede access to the counterparty servers on which the Application is hosted, as well as as a result of the introduction of sanctions;</li>
                                        </ul>
                                    </li>
                                    <li>4. The liability of the Copyright Holder may not exceed the amount of the payment transferred by the User.</li>
                                </ol>
                            </div>
                            {/* Article 11 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 11. Validity Period. Termination of the License Agreement</h2>
                                <i>Validity Period</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The term of the License Agreement corresponds to the term of the subscription.</li>
                                </ol>
                                <i>Termination</i>
                                <ol className={CnContent('orderedList')}>
                                    <li>
                                        2. The User has the right to refuse the terms of the License Agreement by deleting the Application from the Device, however, the terms of the License Agreement in relation to the Content Files shall continue to be in effect even after the termination of the License Agreement.
                                    </li>
                                    <li>
                                        3. The Copyright Holder has the right to terminate the License Agreement in the event of:
                                        <ul className={CnContent('unorderedList')}>
                                            <li>- violation by the User of the terms of the License Agreement (including restrictions);</li>
                                            <li>- the existence of an obligation of the Copyright Holder in accordance with the law or the regulation of the authorized body.</li>
                                        </ul>
                                        In this case, the Copyright Holder terminates the operation of the Application on the User&apos;s Device.
                                    </li>
                                </ol>
                            </div>
                            {/* Article 12 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 12. Dispute Resolution</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The User and the Copyright Holder are obliged to comply with the pre-trial (claims) procedure for resolving disputes.</li>
                                    <li>2. The response period to the claim is 10 (ten) business days from the moment of its receipt by the Recipient Party. The claim is sent to the e-mail address <a href="mailto:support@bitnite.studio">support@bitnite.studio</a>. The absence of a response to the claim cannot be considered as the Copyright Holder&apos;s consent to the arguments set out in the claim.</li>
                                    <li>3. In the event that it is impossible to resolve a dispute through the claims procedure, the dispute shall be considered in court in accordance with the legislation of the Republic of Kazakhstan.</li>
                                    <li>4. When interpreting the terms of the License and resolving disputes, the legislation of the Republic of Kazakhstan shall apply.</li>
                                </ol>
                            </div>
                            {/* Article 13 */}
                            <div className={CnContent('textBlock')}>
                                <h2 className={CnContent('subtitle')}>Article 13. Final Provisions</h2>
                                <ol className={CnContent('orderedList')}>
                                    <li>1. The license agreement is written in English. In case of any discrepancies between the translated and English versions, the English version shall apply.</li>
                                    <li>2. The invalidation of any term does not mean the invalidation of the entire License Agreement.</li>
                                </ol>
                                {/* Contact details */}
                                <div className={CnContent('text')}>
                                    <strong>Contact Details:</strong><br />
                                    Email: <a href="mailto:support@bitnite.studio">support@bitnite.studio</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
};
